import { PageProps } from 'gatsby';
import { ThoughtData } from 'packages/innedit';
import { parse } from 'query-string';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import thought from '~/params/thought.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const ThoughtUpdate: FC<EspaceProps & PageProps & UserProps> = props => {
  const {
    espace,
    user,
    location: { search },
  } = props;

  const model = new ThoughtData({
    espaceId: espace.id,
    params: thought,
  });

  let params;
  if (search) {
    params = parse(search);
  }

  const thoughtId = model.getNewDocId();

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={thoughtId}
          initializeData={params}
          model={model}
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(ThoughtUpdate);
